<template>
  <div class="settings-appointment-sms">
    <div class="d-flex justify-content-end align-items-center w-100">
      <transition name="slide">
        <div v-show="showLegend" class="legend-container">
          <div class="legend-item" v-for="(item, index) in legend" :key="index">
            <span class="text-primary cursor-pointer" @click="copy(item.alias)">
              {{ item.alias }}:</span
            >
            <span class="text-secondary mr-3"> {{ item.description }} </span>
          </div>
        </div>
      </transition>
      <div @click="showLegend = !showLegend">
        <feather-icon icon="InfoIcon" size="30" class="text-warning" />
      </div>
    </div>

    <template v-for="item in appointments_sms">
      <div :key="item.id" class="sms-container">
        <div class="d-flex justify-content-between align-items-center">
          <span class="sms-title">
            {{ item.title }}
          </span>
          <div class="float-right cursor-pointer">
            <feather-icon
              v-if="!item.edit"
              icon="Edit2Icon"
              @click="changeToEdit(item.id)"
              class="text-primary mr-1"
              size="23"
            />
            <feather-icon
              v-if="item.edit"
              icon="XIcon"
              class="text-danger mr-1"
              size="23"
              @click="changeToEdit(item.id)"
            />

            <b-button
              v-if="item.edit"
              :disabled="item.sms.trim().length === 0"
              @click="updateAppointmentSmsService(item)"
              variant="transparent"
            >
              <feather-icon icon="SaveIcon" class="text-primary" size="22" />
            </b-button>
          </div>
        </div>

        <div v-if="!item.edit" class="sms-description" v-colorize="item.sms">
          {{ item.sms }}
        </div>

        <div v-else>
          <validation-observer ref="form">
            <validation-provider v-slot="{ errors }" rules="required">
              <b-form-textarea
                class="sms-description"
                v-model="item.sms"
                no-resize
                :rows="item.sms ? item.sms.split(/\r\n|\r|\n/).length : 0"
                :class="errors[0] ? 'is-invalid' : 'is-valid'"
              ></b-form-textarea>
            </validation-provider>
          </validation-observer>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AppointmentSmsService from "@/views/social-network/views/settings/services/appointment.sms.service.js";
import { mapGetters } from "vuex";

export default {
  name: "SettingsAppointmentSms",
  data() {
    return {
      editorOption: {
        modules: { toolbar: false },
      },
      appointments_sms: [],
      copy_appointments_sms: [],
      showLegend: true,
      legend: [
        {
          alias: "@lead",
          description: "Lead name",
        },
        {
          alias: "@seller_name",
          description: "Seller name",
        },
        {
          alias: "@date",
          description: "Date of appointment",
        },
        {
          alias: "@from",
          description: "Hour from",
        },
        {
          alias: "@to",
          description: "Hour to",
        },
        {
          alias: "@service",
          description: "The service",
        },
        {
          alias: "@seller_number",
          description: "Seller Number",
        },
      ],
    };
  },
  directives: {
    colorize: {
      bind(el, binding, vnode) {
        const text = el.textContent;
        const legend = (vnode.context && vnode.context.legend) || [];

        if (!legend.length) {
          console.warn(
            "Legend array is empty or not defined in component's data."
          );
          return;
        }
        const regex = new RegExp(
          `(${legend.map((item) => `${item.alias}`).join("|")})`,
          "g"
        );
        const coloredText = text.replace(
          regex,
          (match) => `<span style="color: #007bff">${match}</span>`
        );
        el.innerHTML = coloredText;
      },
    },
  },

  async created() {
    this.getAppointmentSmsService();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    copy(text) {
      navigator.clipboard.writeText(text);
      this.showGenericToast({
        text: "Copied to clipboard",
      });
    },
    async getAppointmentSmsService() {
      try {
        const { data } = await AppointmentSmsService.index();
        data.forEach((item) => {
          item.edit = false;
        });
        this.appointments_sms = data;
        this.copy_appointments_sms = JSON.parse(JSON.stringify(data));
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async updateAppointmentSmsService(item) {
      try {
        this.addPreloader();
        const { data } = await AppointmentSmsService.update(item);
        this.getAppointmentSmsService();
        this.showGenericToast({
          text: "Text modified successfully",
        });
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    changeToEdit(id) {
      const index = this.appointments_sms.findIndex((item) => item.id === id);
      this.appointments_sms[index].edit = !this.appointments_sms[index].edit;

      if (!this.appointments_sms[index].edit) {
        this.appointments_sms[index].sms =
          this.copy_appointments_sms[index].sms;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.settings-appointment-sms {
  .sms-container {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    margin: 2.5rem 0;
    font-family: Arial, Helvetica, sans-serif;
    word-wrap: break-word;
    width: 100%;
  }
  .sms-title {
    font-weight: 600;
    color: #111113;
    font-size: 1.7rem;
  }
  .sms-description {
    font-size: 1.4rem;
    margin-top: 2.5rem;
  }
  .legend-container {
    width: 100%;
    position: relative;
    background-color: #fff;
    margin-right: 2rem;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-evenly;
  }
  .legend-item {
    margin-bottom: 1rem;
    display: contents;
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
  }
}

.dark-layout {
  .settings-appointment-sms {
    .sms-title {
      color: #fff;
    }
    .legend-container {
      background-color: #111113;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.long-div {
  width: 100%;
  height: 50px;
  background-color: #3498db; /* or any color you prefer */
}
</style>

