import { amgApi } from "@/service/axios";

class AppointmentSmsService {
    async index(params) {
        try {
            const data = await amgApi.post("social-network/appointment-sms/index", params);
            return data;
        } catch (error) {
            console.log("Error setNote");
        }
    }
    async update(params) {
        try {
            const data = await amgApi.post("social-network/appointment-sms/update", params);
            return data;
        } catch (error) {
            console.log("Error setNote");
        }
    }
}
export default new AppointmentSmsService();