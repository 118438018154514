import { render, staticRenderFns } from "./SettingsAppointmentSms.vue?vue&type=template&id=3ed4a7fa&scoped=true"
import script from "./SettingsAppointmentSms.vue?vue&type=script&lang=js"
export * from "./SettingsAppointmentSms.vue?vue&type=script&lang=js"
import style0 from "./SettingsAppointmentSms.vue?vue&type=style&index=0&id=3ed4a7fa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed4a7fa",
  null
  
)

export default component.exports